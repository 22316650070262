import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { OrderGetCoupon } from '../../../api/order';
import {
  RemoveCart,
  UpdateQtyCart,
  EarnPoint,
  UseCoupon,
  RemoveCoupon,
} from '../../../store/cart';
import MainContainer from '../../../components/MainContainer';
import {
  Wrapper,
  Title,
  OrderInfoWrapper,
  OrderInfo,
  ProductImage,
  ProductDetail,
  Name,
  Price,
  Amount,
  Delete,
  SubTitle,
  InputSection,
  InputWrapper,
  Payment,
  PaymentRow,
  ConfirmButton,
  NullPage,
} from './styled';

const Order = () => {
  const Dispatch = useDispatch();
  const cart_items = useSelector(state => state.cart.cart_items);
  const cart = useSelector(state => state.cart);
  const me = useSelector(state => state.me.user_data);
  const point_min_price = useSelector(state => state.cart.point_min_price);

  const [use_point, set_use_point] = useState(cart.point);
  const [coupon_code, set_coupon_code] = useState(cart.coupon);

  const plusCount = e => {
    Dispatch(UpdateQtyCart(e.currentTarget.dataset.key, '+'));
  };
  const minusCount = e => {
    Dispatch(UpdateQtyCart(e.currentTarget.dataset.key, '-'));
  };

  const RemoveCartItem = e => {
    const key = e.currentTarget.dataset.key;
    Dispatch(RemoveCart(key));
    console.log(key);
  };

  const UsePoint = e => {
    if (cart.subtotal < point_min_price) {
      toast.error(
        `장바구니에  ${point_min_price?.toLocaleString()}원 이상 담아야 사용 가능합니다.`
      );
      set_use_point('');
      return;
    }
    if (parseInt(me.point) < parseInt(use_point)) {
      toast.error('포인트가 부족합니다.');
      set_use_point('');
      return;
    }
    Dispatch(EarnPoint(use_point));
    toast.success(
      <>
        <strong>{cart.point} 포인트</strong> 적용되었습니다.
      </>
    );
  };

  const UseCoupon_ = e => {
    OrderGetCoupon({
      coupon_code: coupon_code,
      cart_items: cart_items,
      subtotal: cart.subtotal,
    })
      .then(response => {
        if (response.data.code == '200') {
          Dispatch(
            UseCoupon(
              response.data.body.coupon_code,
              response.data.body.discount_amount
            )
          );
          toast.success('쿠폰 적용이 완료되었습니다.');
        } else {
          toast.error(response.data.body.message);
        }
      })
      .catch(err => toast.error(err));
  };

  const RemoveCoupon_ = e => {
    Dispatch(RemoveCoupon());
    set_coupon_code('');
    toast.warn('쿠폰 적용 해제되었습니다.');
  };
  return (
    <MainContainer type="small">
      <Wrapper>
        <Title>장바구니</Title>
        <OrderInfoWrapper>
          {cart_items.length ? (
            cart_items.map((item, key) => (
              <OrderInfo>
                <ProductImage url={item.product_image} />
                <ProductDetail>
                  <Name>
                    <span>{item.product_name}</span>
                  </Name>
                  <Price>{parseInt(item.price).toLocaleString('en')}원</Price>
                  <Amount>
                    <div>
                      <img
                        onClick={minusCount}
                        data-key={key}
                        src={process.env.PUBLIC_URL + '/img/minus.png'}
                        alt=""
                      />
                      <span>{item.qty}</span>
                      <img
                        onClick={plusCount}
                        data-key={key}
                        src={process.env.PUBLIC_URL + '/img/plus.png'}
                        alt=""
                      />
                    </div>
                  </Amount>
                </ProductDetail>
                <Delete onClick={RemoveCartItem} data-key={key}>
                  <span>삭제</span>
                </Delete>
              </OrderInfo>
            ))
          ) : (
            <NullPage>
              <img src={process.env.PUBLIC_URL + '/img/mini_basket.png'} />
              <span>고객님의 장바구니가 현재 비어있습니다.</span>
            </NullPage>
          )}
        </OrderInfoWrapper>
        <SubTitle>쿠폰/포인트</SubTitle>
        <InputSection>
          <InputWrapper>
            <input
              className="input-coupon"
              type="text"
              value={coupon_code}
              onChange={e => set_coupon_code(e.target.value)}
              placeholder="쿠폰 코드를 입력해주세요"
            />
            <button onClick={UseCoupon_} className="coupon">
              쿠폰 적용
            </button>
            {cart.coupon ? (
              <a
                href="javascript:;"
                onClick={RemoveCoupon_}
                className="coupon_remove"
              >
                적용 취소
              </a>
            ) : (
              ''
            )}
            {/* <span>보유중인 포인트가 없습니다.</span> */}
          </InputWrapper>
          <InputWrapper>
            <input
              className="input-point"
              onChange={e => {
                if (!isNaN(e.target.value)) {
                  set_use_point(e.target.value);
                }
              }}
              value={use_point}
              type="text"
              placeholder="0"
            />
            <button onClick={UsePoint} className="point">
              사용
            </button>
            {me &&
              (me.point ? (
                <span>
                  <strong>{me.point - cart.point}P</strong> 사용 가능
                </span>
              ) : (
                <span>보유중인 포인트가 없습니다.</span>
              ))}
          </InputWrapper>
        </InputSection>
        <Payment>
          <PaymentRow>
            <span>총 주문금액</span>
            <span>{parseInt(cart.subtotal).toLocaleString('en')}원</span>
          </PaymentRow>
          <PaymentRow>
            <span>쿠폰</span>
            <spann>
              <strong>{cart.coupon ? '[' + cart.coupon + ']' : ''}</strong>{' '}
              {(cart.coupon_total ? '-' : '') +
                parseInt(cart.coupon_total).toLocaleString('en')}
              원
            </spann>
          </PaymentRow>
          <PaymentRow>
            <span>포인트</span>
            <spann>-{parseInt(cart.point).toLocaleString('en')}원</spann>
          </PaymentRow>
          <PaymentRow>
            <span>배송비</span>
            <span>무료배송</span>
          </PaymentRow>
          <PaymentRow className="last-row">
            <span>총 결제금액</span>
            <span className="color">
              {parseInt(cart.total).toLocaleString('en')}원
            </span>
          </PaymentRow>
        </Payment>
        {cart_items.length ? (
          <ConfirmButton to="/order/">주문하기</ConfirmButton>
        ) : (
          ''
        )}
      </Wrapper>
    </MainContainer>
  );
};

export default Order;
