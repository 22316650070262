import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import MainContainer from '../../../../components/MainContainer';
import LoadingRound from '../../../../components/LoadingRound';
import { Br } from '../../../../components/Grid';
import { Link, useParams } from 'react-router-dom';
import { OrderGetPost } from '../../../../api/order';
import QrModal from '../Qr';
import {
    Wrapper,
    Container,
    SingleTitle,
    OrderTime,
    ProductList,
    OrderSummary,
    OrderTrackWrapper,
    OrderTrack,
} from './styled';
import Button from '../../../../components/Button';

const OrderSingle = () => {
    const params = useParams();
    const [order_data, set_order_data] = useState('');
    const [current_ID, set_current_ID] = useState(0);
    useEffect(() => {
        if (current_ID != params.order_ID) {
            set_current_ID(params.order_ID);
            OrderGetPost({ order_ID: params.order_ID })
                .then((response) => {
                    if (response.data.code == '200') {
                        set_order_data(response.data.body);
                    } else {
                        toast.error(response.data.body.message);
                    }
                })
                .catch((err) => toast.error('서버 요청 오류'));
        }
    });

    const [showQr, setShowQr] = useState(false);

    const showKakaoModal = () => {
        setShowQr((p) => !p);
    };

    const qrLink = `https://api-sakuraherb.com/qr_gen.php?url=https://qr.kakaopay.com/281006011141418642009344O${Number(
        order_data?.total_int * 524288
    )?.toString(16)}`;

    if (!order_data) {
        return (
            <>
                <MainContainer type="small">
                    <Wrapper>
                        <Container>
                            <SingleTitle>
                                <h3>주문 상세 보기</h3>
                            </SingleTitle>
                            <LoadingRound />
                        </Container>
                    </Wrapper>
                </MainContainer>
            </>
        );
    }

    return (
        <>
            <MainContainer type="small">
                <Wrapper>
                    <Container>
                        <SingleTitle>
                            <h3>주문 상세 보기</h3>
                        </SingleTitle>
                        <OrderTime>
                            <p>
                                {order_data.order_date ? order_data.order_date : ''}에 생성된 주문{' '}
                                <strong>#{order_data.order_ID}</strong>이 <Br />
                                현재 <small>{order_data.order_status}</small> 상태입니다.
                            </p>
                        </OrderTime>
                        <OrderTrackWrapper>
                            {order_data.ems_slug ? (
                                <OrderTrack>
                                    <div class="title">배송조회</div>
                                    <div class="desc">
                                        우체국 EMS - <span>{order_data.ems_slug}</span>
                                        <a
                                            href={
                                                1
                                                    ? 'https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=' +
                                                      order_data.ems_slug
                                                    : 'https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?POST_CODE=' +
                                                      order_data.ems_slug
                                            }
                                            target="_blank"
                                        >
                                            배송조회
                                        </a>
                                    </div>
                                </OrderTrack>
                            ) : (
                                ''
                            )}
                            {order_data.ems_slug_1 ? (
                                <OrderTrack>
                                    <div class="title">2차 배송조회</div>
                                    <div class="desc">
                                        우체국 EMS - <span>{order_data.ems_slug_1}</span>
                                        <a
                                            href={
                                                order_data.ems_slug_1.slice(0, 1) == 'R'
                                                    ? 'https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=' +
                                                      order_data.ems_slug_1
                                                    : 'https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?POST_CODE=' +
                                                      order_data.ems_slug_1
                                            }
                                            target="_blank"
                                        >
                                            배송조회
                                        </a>
                                    </div>
                                </OrderTrack>
                            ) : (
                                ''
                            )}
                            {order_data.ems_slug_2 ? (
                                <OrderTrack>
                                    <div class="title">3차 배송조회</div>
                                    <div class="desc">
                                        우체국 EMS - <span>{order_data.ems_slug_2}</span>
                                        <a
                                            href={
                                                order_data.ems_slug_2.slice(0, 1) == 'R'
                                                    ? 'https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=' +
                                                      order_data.ems_slug_2
                                                    : 'https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?POST_CODE=' +
                                                      order_data.ems_slug_2
                                            }
                                            target="_blank"
                                        >
                                            배송조회
                                        </a>
                                    </div>
                                </OrderTrack>
                            ) : (
                                ''
                            )}
                            {order_data.ems_slug_3 ? (
                                <OrderTrack>
                                    <div class="title">4차 배송조회</div>
                                    <div class="desc">
                                        우체국 EMS - <span>{order_data.ems_slug_3}</span>
                                        <a
                                            href={
                                                order_data.ems_slug_3.slice(0, 1) == 'R'
                                                    ? 'https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=' +
                                                      order_data.ems_slug_3
                                                    : 'https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?POST_CODE=' +
                                                      order_data.ems_slug_3
                                            }
                                            target="_blank"
                                        >
                                            배송조회
                                        </a>
                                    </div>
                                </OrderTrack>
                            ) : (
                                ''
                            )}
                            {order_data.ems_slug_4 ? (
                                <OrderTrack>
                                    <div class="title">5차 배송조회</div>
                                    <div class="desc">
                                        우체국 EMS - <span>{order_data.ems_slug_4}</span>
                                        <a
                                            href={
                                                order_data.ems_slug_4.slice(0, 1) == 'R'
                                                    ? 'https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=' +
                                                      order_data.ems_slug_4
                                                    : 'https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?POST_CODE=' +
                                                      order_data.ems_slug_4
                                            }
                                            target="_blank"
                                        >
                                            배송조회
                                        </a>
                                    </div>
                                </OrderTrack>
                            ) : (
                                ''
                            )}
                        </OrderTrackWrapper>
                        <ProductList>
                            <div className="line-top">
                                <table>
                                    <tbody>
                                        {order_data.order_items
                                            ? order_data.order_items.map((order_item, key) => (
                                                  <tr>
                                                      <td>{!key ? '상품' : ''}</td>
                                                      <td className="text-right">
                                                          {order_item.name}
                                                          <Br /> (주문수량: {order_item.qty}개)
                                                      </td>
                                                  </tr>
                                              ))
                                            : ''}
                                    </tbody>
                                </table>
                            </div>
                            <div className="line-middle">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>주문번호</td>
                                            <td className="text-right">{order_data.order_ID}</td>
                                        </tr>
                                        <tr>
                                            <td>날짜</td>
                                            <td className="text-right">{order_data.order_date}</td>
                                        </tr>
                                        {order_data.billing_email ? (
                                            <tr>
                                                <td>이메일</td>
                                                <td className="text-right">{order_data.billing_email}</td>
                                            </tr>
                                        ) : (
                                            ''
                                        )}

                                        {/* <tr>
                                            <td>결제방법</td>
                                            <td className="text-right">해외송금</td>
                                        </tr> */}
                                        <tr>
                                            <td>결제방법</td>
                                            <td className="text-right">{order_data.payment_method}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="line">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>배송지정보</td>
                                            <td className="text-right">{order_data.billing_name}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td className="text-right">{order_data.billing_phone}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td className="text-right">{order_data.shipping_country}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td className="text-right">
                                                ({order_data.shipping_postcode}) {order_data.shipping_address1}
                                                <Br /> {order_data.shipping_address2}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>배송메모</td>
                                            <td className="text-right">{order_data.shipping_memo}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="line-bottom">
                                <table>
                                    <tbody>
                                        {/* {order_data.vr_bank ? ( */}
                                        {order_data.order_status == '결제대기중' ? (
                                            <tr>
                                                <td>입금계좌</td>
                                                {/* <td>결제수단</td> */}
                                                <td className="text-right" style={{ lineHeight: '1.3' }}>
                                                    {order_data.vr_bank} {order_data.vr_account}
                                                    {/* {!order_data.is_vr_discount && order_data.vr_account_pass && 0 ? (
                                                        <>
                                                            {order_data.vr_bank} {order_data.vr_account}
                                                            <br />
                                                            <span>
                                                                고객님만을 위한 가상 계좌입니다. 1원 인증을 마친
                                                                계좌에서만 입금할 수 있으며, <br />
                                                                해당 은행의 전용 앱을 통해서만 입금이 가능합니다.
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <a
                                                            href="https://api-sakuraherb.com/auth-account/"
                                                            target="_blank"
                                                            style={{
                                                                color: '#fff',
                                                                fontSize: '12px',
                                                                display: 'inline-block',
                                                                padding: '10px 25px',
                                                                borderRadius: '20px',
                                                                background: '#444',
                                                            }}
                                                        >
                                                            계좌번호 확인하기(클릭)
                                                        </a>
                                                    )} */}
                                                    <Br />
                                                </td>
                                                {/* <td className="text-right">
													<a className="kakao pc" onClick={showKakaoModal}>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="46"
															height="14"
															viewBox="0 0 46 14"
															fill="none"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M6.94124 0C3.10613 0 0 2.44051 0 5.48322C0 7.41662 1.2995 9.12814 3.23291 10.1107L2.56731 12.5829C2.53561 12.6463 2.56731 12.7414 2.6307 12.8048C2.66239 12.8365 2.72579 12.8681 2.75748 12.8681C2.78918 12.8681 2.85257 12.8365 2.88426 12.8365L5.70513 10.9348C6.11717 10.9981 6.52921 11.0298 6.97294 11.0298C10.8081 11.0298 13.9142 8.55763 13.9142 5.54662C13.9142 2.44051 10.8081 0 6.94124 0Z"
																fill="black"
															/>
															<path
																d="M19.7856 10.0305V13.5803H17.25V0.490333H19.0249L19.3419 1.3144C19.8807 0.775587 20.7048 0.205078 22.0043 0.205078C24.4765 0.205078 25.6492 2.04339 25.6492 5.05441C25.6492 8.19221 23.8426 10.189 21.2436 10.189C20.7365 10.2207 20.3561 10.189 19.7856 10.0305ZM19.7856 2.61389V8.2556C19.9124 8.28729 20.261 8.31898 20.6097 8.31898C22.4163 8.31898 23.1136 7.05119 23.1136 5.05441C23.1136 3.31118 22.6382 2.26525 21.2119 2.26525C20.7048 2.26525 20.1977 2.39203 19.7856 2.61389Z"
																fill="black"
															/>
															<path
																d="M31.1353 4.20752H32.5616V3.89057C32.5616 2.84464 31.9594 2.36921 30.9451 2.36921C30.1527 2.36921 29.1702 2.59108 28.3461 3.00311L27.6488 1.32328C28.5363 0.689378 29.9309 0.277344 31.167 0.277344C33.5758 0.277344 34.907 1.54514 34.907 3.98565V9.976H33.1321L32.8785 9.18363C31.8643 9.91261 30.9134 10.2296 30.0893 10.2296C28.251 10.2296 27.2051 9.12024 27.2051 7.25024C27.2051 5.25345 28.568 4.20752 31.1353 4.20752ZM32.5933 7.53549V5.79227H31.4205C30.121 5.79227 29.4554 6.26769 29.4554 7.18684C29.4554 7.88413 29.8041 8.23278 30.5648 8.23278C31.2621 8.26447 32.1495 7.91583 32.5933 7.53549Z"
																fill="black"
															/>
															<path
																d="M42.573 8.99328C41.6855 11.3387 40.6396 13.0502 39.0865 14.0011L37.5334 12.5748C38.4209 11.7824 39.0865 11.0218 39.6253 10.0075L36.2656 0.847679L38.7695 0.182086L40.9248 7.5987L43.0484 0.150391L45.5206 0.847679L42.573 8.99328Z"
																fill="black"
															/>
														</svg>
														카카오페이 결제하기
													</a>
													<a
														className="kakao mobile"
														target="_blank"
														href={`https://qr.kakaopay.com/281006011141418642009344O${Number(
															order_data?.total_int * 524288
														)?.toString(16)}`}
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="46"
															height="14"
															viewBox="0 0 46 14"
															fill="none"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M6.94124 0C3.10613 0 0 2.44051 0 5.48322C0 7.41662 1.2995 9.12814 3.23291 10.1107L2.56731 12.5829C2.53561 12.6463 2.56731 12.7414 2.6307 12.8048C2.66239 12.8365 2.72579 12.8681 2.75748 12.8681C2.78918 12.8681 2.85257 12.8365 2.88426 12.8365L5.70513 10.9348C6.11717 10.9981 6.52921 11.0298 6.97294 11.0298C10.8081 11.0298 13.9142 8.55763 13.9142 5.54662C13.9142 2.44051 10.8081 0 6.94124 0Z"
																fill="black"
															/>
															<path
																d="M19.7856 10.0305V13.5803H17.25V0.490333H19.0249L19.3419 1.3144C19.8807 0.775587 20.7048 0.205078 22.0043 0.205078C24.4765 0.205078 25.6492 2.04339 25.6492 5.05441C25.6492 8.19221 23.8426 10.189 21.2436 10.189C20.7365 10.2207 20.3561 10.189 19.7856 10.0305ZM19.7856 2.61389V8.2556C19.9124 8.28729 20.261 8.31898 20.6097 8.31898C22.4163 8.31898 23.1136 7.05119 23.1136 5.05441C23.1136 3.31118 22.6382 2.26525 21.2119 2.26525C20.7048 2.26525 20.1977 2.39203 19.7856 2.61389Z"
																fill="black"
															/>
															<path
																d="M31.1353 4.20752H32.5616V3.89057C32.5616 2.84464 31.9594 2.36921 30.9451 2.36921C30.1527 2.36921 29.1702 2.59108 28.3461 3.00311L27.6488 1.32328C28.5363 0.689378 29.9309 0.277344 31.167 0.277344C33.5758 0.277344 34.907 1.54514 34.907 3.98565V9.976H33.1321L32.8785 9.18363C31.8643 9.91261 30.9134 10.2296 30.0893 10.2296C28.251 10.2296 27.2051 9.12024 27.2051 7.25024C27.2051 5.25345 28.568 4.20752 31.1353 4.20752ZM32.5933 7.53549V5.79227H31.4205C30.121 5.79227 29.4554 6.26769 29.4554 7.18684C29.4554 7.88413 29.8041 8.23278 30.5648 8.23278C31.2621 8.26447 32.1495 7.91583 32.5933 7.53549Z"
																fill="black"
															/>
															<path
																d="M42.573 8.99328C41.6855 11.3387 40.6396 13.0502 39.0865 14.0011L37.5334 12.5748C38.4209 11.7824 39.0865 11.0218 39.6253 10.0075L36.2656 0.847679L38.7695 0.182086L40.9248 7.5987L43.0484 0.150391L45.5206 0.847679L42.573 8.99328Z"
																fill="black"
															/>
														</svg>
														카카오페이 결제하기
													</a>
												</td> */}
                                            </tr>
                                        ) : // <tr>
                                        // 	<td>입금계좌 및 정보</td>
                                        // 	<td className="text-right">
                                        // 		<a
                                        // 			href="/service/notice/339890"
                                        // 			target="_blank"
                                        // 			className="btn_"
                                        // 		>
                                        // 			결제 방법 안내 [필독]
                                        // 		</a>
                                        // 	</td>
                                        // </tr>
                                        null}
                                    </tbody>
                                </table>
                            </div>
                            <div className="bottom">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="color">총 입금금액</td>
                                            <td className="text-color">{order_data.order_total}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ProductList>
                        {order_data.order_status == '주문대기' || order_data.order_status == '결제대기중'
                            ? ''
                            : // <OrderSummary>
                              //     {/* <p>
                              //         위 카카오페이 결제버튼을 선택하여 결제 부탁드립니다.
                              //         <br />
                              //         <strong>주문자명과 입금자명이 다르면 입금 확인이 어려우니 유의 바랍니다.</strong>
                              //     </p> */}
                              //     <p style={{ fontWeight: 'bold', color: 'red' }}>30분 내에 미입금시 주문 취소됩니다. </p>
                              // </OrderSummary>
                              ''}
                        <br />
                        <Button to="/mypage/orderlist">주문내역</Button>
                    </Container>
                </Wrapper>
                <QrModal show={showQr} onClose={showKakaoModal} qrLink={qrLink} />
            </MainContainer>
        </>
    );
};

export default OrderSingle;
